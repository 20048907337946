import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import * as ethUtil from 'ethereumjs-util';
import Button from '@material-ui/core/Button';
import React, { Fragment } from "react";
import { ValidatorForm } from 'react-material-ui-form-validator';

import { web3 } from "../../store/web3";
import logo from '../../assets/img/logo.png';
import { networkId, message } from "../../store/config";
import { login, toggleLogin, getNonce } from "../../store/actions/Auth";

import './index.css';

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      netId: '',
      address: ''
    };
  };


  async componentDidMount() {
    web3.eth.net.getId((err, netId) => {
      this.setState({ netId })
    });
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };
    this.checkAddresses();
  };

  componentWillReceiveProps() {
    this.checkAddresses();
  };

  componentDidUpdate(prevProps, prevState) {
    let { userNonce } = this.props;
    if (prevProps.userNonce !== userNonce) {
      this.handelLogin(userNonce)
    }
  };

  checkAddresses = async () => {
    let address = (await web3.currentProvider.enable())[0];
    this.setState({ address });
  };

  getNonce = async () => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };

    let { address } = this.state;
    this.props.toggleLogin(true);
    this.props.getNonce({ data: address });
  };

  handelLogin = async (nonce) => {
    if (!window.ethereum) {
      EventBus.publish("error", "Please install metamask");
      return;
    };

    let { address } = this.state;
    let { history } = this.props;

    const signature = await web3.eth.personal.sign(web3.utils.utf8ToHex(`ModernPokerClub,${nonce}`), address);
    console.log("********************signature", signature)
    const msg = new Buffer(`ModernPokerClub,${nonce}`);
    const msgBuffer = ethUtil.toBuffer(msg);
    const msgHash = ethUtil.hashPersonalMessage(msgBuffer);
    const signatureBuffer = ethUtil.toBuffer(signature);
    const signatureParams = ethUtil.fromRpcSig(signatureBuffer);
    const publicKey = ethUtil.ecrecover(
      msgHash,
      signatureParams.v,
      signatureParams.r,
      signatureParams.s
    );
    const addressBuffer = ethUtil.publicToAddress(publicKey);
    const adminAddress = ethUtil.bufferToHex(addressBuffer);
    console.log("********adminAddress", adminAddress)

    const encoded = web3.eth.abi.encodeParameters(['string'], ['admin'])
    // const hash = web3.utils.sha3(encoded, { encoding: 'hex' })
    let data = { publicAddress: adminAddress, signature }
    console.log("********************data", data)
    this.props.toggleLogin(true);
    this.props.login({ data, history });
  };

  render() {
    let { netId } = this.state;
    let { isLogin } = this.props;

    return (
      <div className="login-page">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12 login-area">
            <div className="login-form">
              <p className="login-title">🅻🅾🅶🅸🅽</p>
              <hr className='mt-3' />
              {(netId !== networkId)
                ? <div className="login-text pt-4"><h4>{message}</h4></div>
                : <Fragment>
                  <ValidatorForm className="validator-form mt-4" onSubmit={this.getNonce}>
                    <Button type="Submit" variant="contained" className='text-white login-btn mt-4' disabled={isLogin} >
                      {!isLogin
                        ? 'LOGIN WITH METAMASK'
                        : <i className="fa fa-spinner fa-spin fa-fw"></i>
                      }
                    </Button>
                  </ValidatorForm>
                </Fragment>
              }
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 login-area">
            <img className="login-page-logo" src={logo} alt='logo' />
          </div>
        </div>
      </div >
    );
  }
}

const mapDispatchToProps = {
  login,
  getNonce,
  toggleLogin,
};

const mapStateToProps = ({ Auth }) => {
  let { isLogin, userNonce } = Auth
  return { isLogin, userNonce }
};
export default connect(mapStateToProps, mapDispatchToProps)(Login);